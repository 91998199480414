.App {
  text-align: center;
  width: '100%';
}

.blink {
  animation: blinker 1.5s linear infinite;
  font-family: sans-serif;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}