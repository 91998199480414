.header {
  position: fixed;
  height: 80px;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
  background-color: #ffffff;
  border-bottom: 1px solid grey;
  box-shadow: 0px 10px 10px -15px #111;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.header .navbar img {
  width: 125px;
  height: 65px;
}

.header .nav-menu {
  display: flex;
}

.nav-menu-mobile {
  display: flex;
  flex-direction: column;
}


@media screen and (max-width:980px) {
  .header .nav-menu {
    display: none;
  }
}

@media screen and (min-width:980px) {
  .mobileHeader {
    display: none;
  }
}

.color1 {
  color: #212529;
}

.header .nav-item {
  padding: 0.8rem;
  font-weight: 400;
  font-size: 16px;
  color: #000000e6;
  list-style-type: none;
}

.header .nav-item a:hover {
  padding-bottom: 12px;
  color: #1387ca;
  text-decoration: none;
}

.active {
  transition: 0.3;
  padding-bottom: 12px;
  color: #1387ca !important;
  text-decoration: none;
  border-bottom: 3px solid #1387ca;
}

.miniPopup {
  position: fixed;
  width: 280px;
  height: auto;
  right: 30px;
  top: 80px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  z-index: 10;
  pointer-events: all;
  box-shadow: rgb(0 0 0 / 35%) 0px 1px 4px;
}